








































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsCustomFieldGenerator from '@/modules/lists/components/VsCustomFieldGenerator/Index.vue'
import VsTagNameModal from '@/modules/lists/components/VsTagNameModal/Index.vue'
import {
    CustomFieldTypeEnum,
    CustomField,
} from '@/utils/customFields'
import { createTag, getList, getListCustomFields, getTags, subscribeContact, SubscribeContactDto } from '@/api/consoleApi/recipients'
import {
    ContactStatusEnum,
} from '@/api/consoleApi/types'
import CollapseTransition from '@ivanv/vue-collapse-transition'
import moment from 'moment'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import axios from 'axios'
import { unionBy } from 'lodash'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'CreateContact',
    components: {
        VsContainer,
        VsSidebarLayout,
        VsSectionHeader,
        VsCustomFieldGenerator,
        CollapseTransition,
        VsLoader,
        VsTagNameModal,
    },
})
export default class extends Vue {
    private email = ''
    private mobile = ''
    private list: any = null
    private customFields: CustomField[] = []
    private customFieldsValues: {[key: string]: any} | null = null
    private showOptional = false
    private optInEmail = false
    private updateIfDuplicate = false
    private triggers = false
    private optinAcceptance = []
    private status = 'subscribed'
    private loading = false
    private loadingTags = false
    private loadingSubmit = false
    private selectedTagMulti: any[] = []
    private tags: any[] = []
    private cachedTags: any[] = []
    private assignedTagIds: any[] = []
    $refs: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    get requiredFields () {
        return this.customFields.filter((el) => el.validation.required)
    }

    get optionalFields () {
        return this.customFields.filter((el) => !el.validation.required)
    }

    get tagsOptions () {
        return this.tags.filter(el => !this.assignedTagIds.includes(el.id)).map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })
    }

    get user () {
        return UserModule.user
    }

    get hasTag () {
        return this.user.configuration.rules.tag
    }

    async beforeMount () {
        this.loading = true
        await this.getList(parseInt(this.listId))
        await this.getListCustomFields(parseInt(this.listId))
        await this.getTags('')
        this.cachedTags = this.tagsOptions
        this.loading = false
    }

    private async getList (listId: number) {
        try {
            const resp = await getList(listId)
            this.list = resp.data.data
            if (this.list?.opt_in.mode === 'double') this.status = 'optin'
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) this.$router.replace({ name: 'listsIndex' })
            }
            console.log(e)
        }
    }

    private async getListCustomFields (listId: number) {
        try {
            this.customFields = await this.customFieldsLoop(listId, 1)
            this.generateCustomFieldsValuesObject()
        } catch (e) {
            console.log(e)
        }
    }

    private async getTags (search: string) {
        this.loadingTags = true
        try {
            const resp = await getTags(
                this.listId,
                {
                    limit: 50,
                    search: `name:${search}`,
                    searchFields: 'name:like',
                })
            this.tags = resp.data.data
            this.setCachedTags(resp.data.data)
        } catch (e) {
            this.tags = []
        }
        this.loadingTags = false
    }

    private setCachedTags (tags: any[]) {
        this.cachedTags = unionBy(
            this.cachedTags,
            tags
                .map((tag: any) => {
                    return {
                        value: tag.id,
                        label: tag.name,
                    }
                }),
            'value',
        )
    }

    async createTag (e: any) {
        try {
            const data = {
                name: e.name,
            }
            await createTag(this.listId, data)
            this.$refs.tagNameModal.closeModal()
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Etichetta creato con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('tag-created')
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Qualcosa è andato storto',
                aspect: VsToastAspectEnum.alert,
            })
            this.$refs.tagNameModal.loading = false
            console.log(e)
        }
    }

    private async customFieldsLoop (listId: number, page: number): Promise<CustomField[]> {
        const resp = await getListCustomFields(listId, { page })
        const customFields: CustomField[] = resp.data.data
        let fields: CustomField[] = []
        if (
            resp.data.meta.pagination.current_page < resp.data.meta.pagination.total_pages &&
            resp.data.meta.pagination.current_page === page
        ) {
            page = page + 1
            fields = await this.customFieldsLoop(listId, page)
        }
        return [
            ...customFields,
            ...fields,
        ]
    }

    private generateCustomFieldsValuesObject () {
        const newCustomFieldsValues: {[key: string]: any} = {}
        for (const field of this.customFields) {
            newCustomFieldsValues[field.id + ''] = this.getCustomFieldEmptyValue(field)
        }
        this.customFieldsValues = newCustomFieldsValues
    }

    private getCustomFieldEmptyValue (customField: CustomField) {
        if (customField.default_value) return customField.default_value
        if (
            customField.type === CustomFieldTypeEnum.checkboxes
        ) {
            return []
        }
        return ''
    }

    private async submitForm () {
        if (this.loadingSubmit) return
        const isValid = await this.$refs.contactForm.validate()
        const isValidSelectedTag = this.selectedTagMulti.length <= 3
        if (!isValid || !isValidSelectedTag) {
            this.$root.$vsToast({
                heading: 'Errore durante l\'inserimento del contatto, ricontrolla i campi',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            return
        }
        this.loadingSubmit = true
        try {
            await subscribeContact(this.listId, this.generateSubscribeData())
            this.$root.$vsToast({
                heading: 'Contatto inserito con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.resetForm()
        } catch (e) {
            let heading = 'Errore durante l\'inserimento del contatto, ricontrolla i campi'
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    const message = e.response?.data?.message
                    if (message) {
                        for (const error in message) {
                            if (error.includes('custom_fields.') || message[error][0] === 'Subscriber alredy exist') {
                                const messageError = message[error][0]
                                if (messageError && (messageError.includes('has already been taken.') || messageError === 'Subscriber alredy exist')) {
                                    heading = 'Il contatto non può essere inserito perché risulta essere duplicato, puoi modificarlo dalla sua scheda contatto.</br> <a href="/user/lists/' + this.listId + '/contacts" style="color:white" class="vs-h200 vs-underline">Visualizza elenco contatti</a>'
                                }
                            }
                        }
                    }
                }
            }
            this.$root.$vsToast({
                heading,
                aspect: VsToastAspectEnum.alert,
                timeout: 8000,
            })
            this.addServerErrors(e)
        }
        this.loadingSubmit = false
    }

    private addServerErrors (e: unknown) {
        if (axios.isAxiosError(e)) {
            if (e.response?.status === 400) {
                const message = e.response?.data?.message
                if (message) {
                    const fieldNames = Object.keys(this.customFieldsValues || {})
                    for (const error in message) {
                        if (error.includes('custom_fields.')) {
                            const errArr = error.split('.')
                            this.$refs.contactForm.setErrors({
                                [fieldNames[parseInt(errArr[1])]]: this.getMessageError(message[error][0]),
                            })
                        }
                        if (error.includes('mobile')) {
                            this.$refs.contactForm.setErrors({
                                mobile: this.getMessageError(message[error][0]),
                            })
                        }
                        if (error.includes('email_address')) {
                            this.$refs.contactForm.setErrors({
                                email: this.getMessageError(message[error][0]),
                            })
                        }
                    }
                }
            }
        }
    }

    /* questa parte deve diventare una utils con le stringhe definite in base alla stringa match esatto */
    private getMessageError (messageError: string) {
        if (!messageError) return 'Il campo non ha un formato valido'

        if (messageError.includes('mobile number is not valid')) {
            return this.$t('customValidations.libphoneNumberMobile')
        }
        if (messageError.includes('has already been taken.')) {
            return 'Il valore inserito deve essere univoco'
        }
        if (messageError.includes('field is required.')) {
            return 'Il campo è obbligatorio'
        }
        if (messageError.includes('must be a number.')) {
            return 'Il campo deve essere un numero'
        }
        if (messageError.includes('field must be true or false.')) {
            return 'Il campo deve essere di tipo buleano'
        }
        if (messageError.includes('must be a valid email address.')) {
            return 'Il campo deve essere un indirizzo email valido'
        }
        if (messageError.includes('must be valid date (format YYYY-MM-DD)') || messageError.includes('is not a valid date.')) {
            return 'Il campo deve essere una data valida (formato YYYY-MM-DD)'
        }
        if (messageError.includes('must be valid time string (HH:MM:SS)')) {
            return 'Il campo non ha un formato di tipo temporale valido (formato HH:MM:SS)'
        }
        if (messageError.includes('must be a valid IP address.')) {
            return 'Il campo deve essere un indirizzo IP valido'
        }

        return 'Il campo non ha un formato valido'
    }

    private generateSubscribeData (): SubscribeContactDto {
        return {
            update_if_duplicate: this.updateIfDuplicate,
            mobile: this.customFieldsValues?.mobile,
            email_address: this.customFieldsValues?.email,
            subscription: {
                ip: '0.0.0.0',
                status: this.status === 'subscribed' ? ContactStatusEnum.subscribed : ContactStatusEnum.optIn,
                date: moment().format(),
            },
            triggers: {
                automation: this.triggers,
                behaviors: this.triggers,
                send_confirmation_email: this.optInEmail && this.status !== 'subscribed',
            },
            custom_fields: this.generateCustomFieldsFromValues(),
            tags: this.selectedTagMulti,
        }
    }

    private generateCustomFieldsFromValues () {
        const customFields = []
        for (const fieldId in this.customFieldsValues) {
            if (['mobile', 'email'].includes(fieldId)) continue
            customFields.push({
                id: parseInt(fieldId),
                value: this.parseEmptyArrayValues(this.customFieldsValues[fieldId]),
            })
        }
        return customFields
    }

    private parseEmptyArrayValues (value: any) {
        if (Array.isArray(value) && value.length === 0) return null
        return value
    }

    private resetForm () {
        this.status = 'subscribed'
        this.optinAcceptance = []
        this.selectedTagMulti = []
        this.optInEmail = false
        this.triggers = false
        this.generateCustomFieldsValuesObject()
        this.$refs.contactForm.reset()
    }

    formatBadgeLabel (item: any) {
        return this.cachedTags.find((el: any) => el.value === item)?.label || `Etichetta con id ${item} non trovata`
    }
}
